<div
    routerLink="/perfil"
    class="flex flex-row items-center w-full px-4 py-2 gap-4 cursor-pointer"
>
    <div *ngIf="showAvatar" class="w-10 h-10">
        <img
            class="w-10 rounded-full"
            [src]="'assets/images/avatars/avatar.jpg'"
            alt="User avatar"
        />

        <!--        <span-->
        <!--            class=" block -mt-1 w-2 h-2 rounded-full"-->
        <!--            [ngClass]="{'mr-px mb-px': !showAvatar,'bg-green-500': !user?.deleted_at,'bg-red-500': user?.deleted_at }"-->
        <!--        ></span>-->
    </div>
    <div class="flex flex-auto flex-col items-start justify-center">
        <div
            class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal font-medium"
        >
            {{ user?.login }}
        </div>

        <div
            class="w-full whitespace-nowrap text-ellipsis overflow-hidden leading-normal font-light text-secondary"
        >
            {{ user?.role }}
        </div>
    </div>
</div>
