import { Component, Inject, inject } from '@angular/core';
import {
    MAT_SNACK_BAR_DATA,
    MatSnackBarRef,
} from '@angular/material/snack-bar';
import { SnackBarMultiLineData } from './app-snackbar.service';

@Component({
    selector: 'app-app-snackbar',
    templateUrl: './app-snackbar.component.html',
    styleUrls: ['./app-snackbar.component.scss'],
})
export class AppSnackbarComponent {
    public snackBarRef = inject(MatSnackBarRef);

    constructor(
        @Inject(MAT_SNACK_BAR_DATA) public data: SnackBarMultiLineData
    ) {}
}
