import { Injectable } from '@angular/core';
import md5 from 'md5';
import { AcronymList } from '../modules/admin/types';
import { FormGroup } from '@angular/forms';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client/core';

@Injectable()
export class HelpersService {
    constructor(private _apollo: Apollo) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public clear(): Observable<string> {
        const CLEAR_CACHE = gql`
            query {
                clearCache
            }
        `;
        return this._apollo
            .watchQuery({
                query: CLEAR_CACHE,
                fetchPolicy: 'no-cache',
            })
            .valueChanges.pipe(
                map(
                    (
                        result: ApolloQueryResult<{
                            clearCache: string;
                        }>
                    ) => result.data.clearCache
                )
            );
    }

    public countriesOptions: AcronymList[] = [
        { acronym: 'BR', name: 'Brasil' },
        { acronym: 'PT', name: 'Portugal' },
    ];

    public brazilStates: AcronymList[] = [
        { acronym: 'AC', name: 'Acre' },
        { acronym: 'AL', name: 'Alagoas' },
        { acronym: 'AP', name: 'Amapá' },
        { acronym: 'AM', name: 'Amazonas' },
        { acronym: 'BA', name: 'Bahia' },
        { acronym: 'CE', name: 'Ceará' },
        { acronym: 'DF', name: 'Distrito Federal' },
        { acronym: 'ES', name: 'Espírito Santo' },
        { acronym: 'GO', name: 'Goiás' },
        { acronym: 'MA', name: 'Maranhão' },
        { acronym: 'MT', name: 'Mato Grosso' },
        { acronym: 'MS', name: 'Mato Grosso do Sul' },
        { acronym: 'MG', name: 'Minas Gerais' },
        { acronym: 'PA', name: 'Pará' },
        { acronym: 'PB', name: 'Paraíba' },
        { acronym: 'PR', name: 'Paraná' },
        { acronym: 'PE', name: 'Pernambuco' },
        { acronym: 'PI', name: 'Piauí' },
        { acronym: 'RJ', name: 'Rio de Janeiro' },
        { acronym: 'RN', name: 'Rio Grande do Norte' },
        { acronym: 'RS', name: 'Rio Grande do Sul' },
        { acronym: 'RO', name: 'Rondônia' },
        { acronym: 'RR', name: 'Roraima' },
        { acronym: 'SC', name: 'Santa Catarina' },
        { acronym: 'SP', name: 'São Paulo' },
        { acronym: 'SE', name: 'Sergipe' },
        { acronym: 'TO', name: 'Tocantins' },
    ];

    public portugalDepartments: AcronymList[] = [
        { acronym: 'AVE', name: 'Aveiro' },
        { acronym: 'ACO', name: 'Açores' },
        { acronym: 'BJA', name: 'Beja' },
        { acronym: 'BRG', name: 'Braga' },
        { acronym: 'BMO', name: 'Bragança' },
        { acronym: 'CBR', name: 'Castelo Branco' },
        { acronym: 'COI', name: 'Coimbra' },
        { acronym: 'EVH', name: 'Évora' },
        { acronym: 'FAR', name: 'Faro' },
        { acronym: 'GRD', name: 'Guarda' },
        { acronym: 'LEI', name: 'Leiria' },
        { acronym: 'LIS', name: 'Lisboa' },
        { acronym: 'MAD', name: 'Madeira' },
        { acronym: 'PLT', name: 'Portalegre' },
        { acronym: 'PRT', name: 'Porto' },
        { acronym: 'SNT', name: 'Santarém' },
        { acronym: 'STM', name: 'Setúbal' },
        { acronym: 'VDP', name: 'Viana do Castelo' },
        { acronym: 'VRL', name: 'Vila Real' },
        { acronym: 'VSE', name: 'Viseu' },
    ];

    /**
     * randomString generate random string
     *
     * @param length:number
     */
    public randomString = (length: number): string => {
        let result = '';
        const characters = 'abcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(
                Math.floor(Math.random() * charactersLength)
            );
            counter += 1;
        }
        return result;
    };

    public getTempPassword = function (item) {
        const date = new Date();
        const key = `${item.login}-${date.getUTCDate()}-visio`;
        const hash = md5(key);
        return hash.substring(0, 6);
    };

    public slugify = (text: string) =>
        text
            .toString()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
            .toLowerCase()
            .trim()
            .replace(/\s+/g, '-')
            .replace(/[^\w-]+/g, '')
            .replace(/--+/g, '-');

    /**
     * @description
     * Takes an Array<V>, and a grouping function,
     * and returns a Map of the array grouped by the grouping function.
     *
     * @param list An array of type V.
     * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
     *                  K is generally intended to be a property key of V.
     *
     * @returns Map of the array grouped by the grouping function.
     */
    //export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
    //    const map = new Map<K, Array<V>>();
    public groupBy = (list, keyGetter) => {
        const map = new Map();
        list.forEach((item) => {
            const key = keyGetter(item);
            const collection = map.get(key);
            if (!collection) {
                map.set(key, [item]);
            } else {
                collection.push(item);
            }
        });
        return map;
    };

    public enumToArray = (enumObject: any) => {
        return Object.keys(enumObject).map((key) => enumObject[key]);
    };

    public joinWithConjunction = (array, conjunction = 'e') => {
        if (!array) {
            return '';
        }
        if (array.length === 0) {
            return '';
        } else if (array.length === 1) {
            return array[0].toString();
        } else {
            return (
                array.slice(0, -1).join(', ') +
                ` ${conjunction} ` +
                array.slice(-1)
            );
        }
    };

    public logValidationErrors(form: FormGroup<any>) {
        Object.keys(form.controls).forEach((key) => {
            const controlErrors = form.get(key).errors;
            if (controlErrors != null) {
                Object.keys(controlErrors).forEach((keyError) => {
                    const errorMessage = `Key control: ${key}, keyError: ${keyError}, error value: ${controlErrors[keyError]}`;
                    console.log('error', errorMessage);
                });
            }
        });
    }
}

//export function groupBy<K, V>(list: Array<V>, keyGetter: (input: V) => K): Map<K, Array<V>> {
//    const map = new Map<K, Array<V>>();
// public groupByA = (list, keyGetter) => {
//     const map = new Map();
//     list.forEach((item) => {
//         const key = keyGetter(item);
//         const collection = map.get(key);
//         if (!collection) {
//             map.set(key, [item]);
//         } else {
//             collection.push(item);
//         }
//     });
//     return map;
// }
