import { LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, PreloadAllModules, RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { FuseMockApiModule } from '@fuse/lib/mock-api';
import { CoreModule } from 'app/core/core.module';
import { appConfig } from 'app/core/config/app.config';
import { mockApiServices } from 'app/mock-api';
import { LayoutModule } from 'app/layout/layout.module';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { APOLLO_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';
import { InMemoryCache } from '@apollo/client/core';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import {
    HTTP_INTERCEPTORS,
    HttpClientModule,
    HttpHeaders,
} from '@angular/common/http';
import { ApolloInterceptor } from './app.interceptor';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppSnackbarModule } from './shared/app-snackbar/app-snackbar.module';
import { environment } from '../environments/environment';
import { registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';

// @ts-ignore
import extractFiles from 'extract-files/extractFiles.mjs';
// @ts-ignore
import isExtractableFile from 'extract-files/isExtractableFile.mjs';

const routerConfig: ExtraOptions = {
    preloadingStrategy: PreloadAllModules,
    scrollPositionRestoration: 'enabled',
};
registerLocaleData(localePt);

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        RouterModule.forRoot(appRoutes, routerConfig),
        HttpClientModule,

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),

        ApolloModule,

        // Core module of your application
        CoreModule,

        // Layout module of your application
        LayoutModule,
        AppSnackbarModule,
    ],
    bootstrap: [AppComponent],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory(httpLink: HttpLink) {
                return {
                    cache: new InMemoryCache(),
                    link: httpLink.create({
                        uri: environment.graphqlUrl,
                        headers: new HttpHeaders({
                            'Apollographql-Client-Name': environment.clientName,
                            'Apollographql-Client-Version':
                                environment.clientVersion,
                            'Client-Platform': environment.clientPlatform,
                            'X-Accept-Language': environment.language,
                            'Time-Zone': environment.timeZone,
                        }),
                        extractFiles: (body) => extractFiles(body, isExtractableFile)
                    }),
                };
            },
            deps: [HttpLink],
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ApolloInterceptor,
            multi: true,
        },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        provideEnvironmentNgxMask(),
    ],
})
export class AppModule {
    /**
     * Constructor
     */
}
