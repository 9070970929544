import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneBrazil',
})
export class PhoneBrazilPipe implements PipeTransform {
    transform(phone: string): string {
        if (!phone) {
            return phone;
        }

        // Remover todos os caracteres não numéricos
        phone = phone.replace(/\D/g, '');

        // Verificar o comprimento do número de telefone
        if (phone.length === 10) {
            // Formatar número fixo: (XX) XXXX-XXXX
            return `(${phone.substr(0, 2)}) ${phone.substr(
                2,
                4
            )}-${phone.substr(6)}`;
        } else if (phone.length === 11) {
            // Formatar número de celular: (XX) XXXXX-XXXX
            return `(${phone.substr(0, 2)}) ${phone.substr(
                2,
                5
            )}-${phone.substr(7)}`;
        } else {
            // Retornar número não formatado
            return phone;
        }
    }
}
