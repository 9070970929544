import { Injectable } from '@angular/core';
import {
    HttpErrorResponse,
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
} from '@angular/common/http';
import { filter, Observable, tap, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { ApolloError } from '@apollo/client/core';
import {
    AppSnackbarService,
    SnackBarMessageTypeEnum,
} from './shared/app-snackbar/app-snackbar.service';
import { Router } from '@angular/router';

type ValidationErrorGraphql = {
    field: string;
    message: string;
    validation: string;
};

type ErrorGraphql = {
    message: string;
    extensions: {
        code?: string;
        httpCode?: number;
        exception?: string;
        validation?: Array<ValidationErrorGraphql>;
    };
};

type ResponseErrorGraphql = {
    errors: ErrorGraphql[];
};

@Injectable()
export class ApolloInterceptor implements HttpInterceptor {
    constructor(
        private _snackBar: AppSnackbarService,
        private _router: Router
    ) {}

    intercept(
        req: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            filter((event) => event instanceof HttpResponse),
            tap((event: HttpResponse<any>) => {
                this.hasError(event);
                this.hasSuccessMessage(event);
            }),
            catchError((error: HttpErrorResponse | ApolloError) => {
                if (error?.name) {
                    this._snackBar.openSnackBar(
                        `Falha ao se comunicar com servidor: [${error?.name}] ${error?.message}`,
                        SnackBarMessageTypeEnum.error
                    );
                }

                return throwError(() => error);
            })
        );
    }

    private hasSuccessMessage(event: HttpResponse<any>): void {
        const isOk = event.status === 200 && event.body.data;

        if (!isOk) {
            return null;
        }

        for (const key in event.body.data) {
            if (event.body.data.hasOwnProperty(key)) {
                const element = event.body.data[key];
                if (element.message) {
                    this._snackBar.openSnackBar(
                        element.message,
                        SnackBarMessageTypeEnum.success
                    );
                }
            }
        }
    }

    private hasError(event: HttpResponse<ResponseErrorGraphql>) {
        const isError = event.status === 200 && event.body.errors?.length > 0;

        if (!isError) {
            return null;
        }

        event.body.errors.forEach((err) => {
            if (
                err.extensions.code === 'e_unauthorized' &&
                err.extensions.httpCode === 401
            ) {
                this._snackBar.openSnackBar(
                    'Faça login para continuar.',
                    SnackBarMessageTypeEnum.error,
                    7000
                );
                this._router.navigate(['/sair']);
            } else if (
                err.extensions.code === 'e_unauthorized' &&
                (err.extensions.httpCode === 429 ||
                    err.extensions.httpCode === 403)
            ) {
                this._snackBar.openSnackBar(
                    'Você não tem autorização para acessar isto.',
                    SnackBarMessageTypeEnum.error,
                    7000
                );
                this._router.navigate(['/404-not-found']);
            } else if (err.extensions?.validation?.length) {
                const fields: string[] = err.extensions.validation.map(
                    (err) => {
                        return `[${err.field}] ${err.message}.`;
                    }
                );

                this._snackBar.openSnackBarMultiLine(
                    err.message,
                    fields,
                    SnackBarMessageTypeEnum.error
                );
            } else if (err.message) {
                this._snackBar.openSnackBar(
                    err.message,
                    SnackBarMessageTypeEnum.error
                );
            }
        });
    }
}
