import { Route } from '@angular/router';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { InitialDataResolver } from 'app/app.resolvers';
import { AccountModule } from './modules/admin/account/account.module';

// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'contas' },
    // {path: '', pathMatch: 'full', redirectTo: 'home'},

    // Redirect signed-in user to the '/example'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'contas' },
    // Error
    {
        path: 'error',
        children: [
            {
                path: '404',
                loadChildren: () =>
                    import('app/modules/error/error-404/error-404.module').then(
                        (m) => m.Error404Module
                    ),
            },
            {
                path: '500',
                loadChildren: () =>
                    import('app/modules/error/error-500/error-500.module').then(
                        (m) => m.Error500Module
                    ),
            },
        ],
    },
    // Auth routes for guests
    {
        path: '',
        canMatch: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            // {
            //     path: 'confirmation-required',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/confirmation-required/confirmation-required.module'
            //         ).then((m) => m.AuthConfirmationRequiredModule),
            // },
            // {
            //     path: 'forgot-password',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/forgot-password/forgot-password.module'
            //         ).then((m) => m.AuthForgotPasswordModule),
            // },
            // {
            //     path: 'reset-password',
            //     loadChildren: () =>
            //         import(
            //             'app/modules/auth/reset-password/reset-password.module'
            //         ).then((m) => m.AuthResetPasswordModule),
            // },
            {
                path: 'login',
                loadChildren: () =>
                    import('app/modules/auth/sign-in/sign-in.module').then(
                        (m) => m.AuthSignInModule
                    ),
            },
            // {
            //     path: 'sair',
            //     loadChildren: () =>
            //         import('app/modules/auth/sign-up/sign-up.module').then(
            //             (m) => m.AuthSignUpModule
            //         ),
            // },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'sair',
                loadChildren: () =>
                    import('app/modules/auth/sign-out/sign-out.module').then(
                        (m) => m.AuthSignOutModule
                    ),
            },
            {
                path: 'unlock-session',
                loadChildren: () =>
                    import(
                        'app/modules/auth/unlock-session/unlock-session.module'
                    ).then((m) => m.AuthUnlockSessionModule),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () =>
                    import('app/modules/landing/home/home.module').then(
                        (m) => m.LandingHomeModule
                    ),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canMatch: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: InitialDataResolver,
        },
        children: [
            // {
            //     path: 'example',
            //     loadChildren: () =>
            //         import('app/modules/admin/example/example.module').then(
            //             (m) => m.ExampleModule
            //         ),
            // },
            {
                path: 'contas',
                loadChildren: () =>
                    import('app/modules/admin/account/account.module').then(
                        (m) => m.AccountModule
                    ),
            },
            {
                path: 'perfil',
                loadChildren: () =>
                    import('app/modules/admin/profile/profile.module').then(
                        (m) => m.ProfileModule
                    ),
            },
            {
                path: 'conta-nao-encontrada',
                loadChildren: () =>
                    import(
                        'app/modules/admin/account-not-found/account-not-found.module'
                    ).then((m) => m.AccountNotFoundModule),
            },
            {
                path: 'capacitacao',
                loadChildren: () =>
                    import('app/modules/admin/academy/academy.module').then(
                        (m) => m.AcademyModule
                    ),
            },
            {
                path: 'clear',
                loadChildren: () =>
                    import('app/modules/admin/clear/clear.module').then(
                        (m) => m.ClearModule
                    ),
            },
        ],
    },
    // 404 & Catch all
    {
        path: '404-not-found',
        pathMatch: 'full',
        loadChildren: () =>
            import('app/modules/error/error-404/error-404.module').then(
                (m) => m.Error404Module
            ),
    },
    { path: '**', redirectTo: '404-not-found' },
];
