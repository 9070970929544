import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core';
import { Apollo, gql } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { SearchItem, SearchItemTypeEnum } from '../../../graphql/generated';

@Injectable({
    providedIn: 'root',
})
export class SearchService {
    /**
     * Constructor
     */
    constructor(private _apollo: Apollo) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    public search(
        term: string,
        type?: SearchItemTypeEnum,
        limit = 5
    ): Observable<SearchItem[]> {
        const SEARCH = gql`
            query search(
                $term: String!
                $type: SearchItemTypeEnum
                $limit: Int
            ) {
                search(term: $term, type: $type, limit: $limit) {
                    name
                    type
                    id
                    account_id
                    account_name
                }
            }
        `;
        return this._apollo
            .watchQuery({
                query: SEARCH,
                variables: type ? { term, type, limit } : { term, limit },
            })
            .valueChanges.pipe(
                map(
                    (
                        result: ApolloQueryResult<{
                            search: SearchItem[];
                        }>
                    ) => result.data.search
                )
            );
    }
}
