import {
    Component,
    ElementRef,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ViewChild,
    ViewEncapsulation,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { Router } from '@angular/router';
import { debounceTime, filter, map, Subject, takeUntil } from 'rxjs';
import { cloneDeep } from 'lodash-es';
import {
    FuseNavigationItem,
    FuseNavigationService,
} from '../../../../@fuse/components/navigation';
import { fuseAnimations } from '@fuse/animations/public-api';
import { SearchService } from '../../../shared/search.service';
import {
    SearchItem,
    SearchItemTypeEnum,
} from '../../../../../graphql/generated';
import { HelpersService } from '../../../shared/helpers.service';
import { defaultNavigation } from '../../../mock-api/common/navigation/data';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

type BlockOfResults = {
    id: string;
    label: string;
    results: SearchItem[];
};

@Component({
    // eslint-disable-next-line @angular-eslint/component-selector
    selector: 'search',
    templateUrl: './search.component.html',
    encapsulation: ViewEncapsulation.None,
    exportAs: 'fuseSearch',
    animations: fuseAnimations,
})
export class SearchComponent implements OnInit, OnDestroy {
    @Input() appearance: 'basic' | 'bar' = 'basic';
    @Input() debounce = 300;
    @Input() minLength = 2;
    @Output() search: EventEmitter<any> = new EventEmitter<any>();

    isMobile: boolean;
    opened = false;
    resultSets: BlockOfResults[];
    searchControl: UntypedFormControl = new UntypedFormControl();
    private _matAutocomplete: MatAutocomplete;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private readonly _defaultNavigation: FuseNavigationItem[] =
        defaultNavigation;

    /**
     * Constructor
     */
    constructor(
        private _elementRef: ElementRef,
        private _httpClient: HttpClient,
        private _renderer2: Renderer2,
        private _search: SearchService,
        private _helper: HelpersService,
        private _fuseNavigationService: FuseNavigationService,
        private router: Router,
        private breakpointObserver: BreakpointObserver
    ) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter for mat-autocomplete element reference
     * @param value
     */
    @ViewChild('matAutocomplete')
    set matAutocomplete(value: MatAutocomplete) {
        this._matAutocomplete = value;
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Lifecycle hooks
    // -----------------------------------------------------------------------------------------------------

    /**
     * On init
     */
    ngOnInit(): void {
        this.router.routeReuseStrategy.shouldReuseRoute = () => false;

        this.searchControl.valueChanges
            .pipe(
                debounceTime(this.debounce),
                takeUntil(this._unsubscribeAll),
                map((value) => {
                    if (!value || value.length < this.minLength) {
                        this.resultSets = null;
                    }

                    return value;
                }),

                filter((value) => value && value.length >= this.minLength)
            )
            .subscribe((value) => {
                this._search.search(value, null, 10).subscribe({
                    next: (response) => {
                        if (response) {
                            const grouped = this._helper.groupBy(
                                response,
                                (r) => r.type
                            );

                            // Filter the navigation
                            const flatNavigation =
                                this._fuseNavigationService.getFlatNavigation(
                                    this._defaultNavigation
                                );
                            const pagesResults = cloneDeep(
                                flatNavigation
                            ).filter(
                                (page) =>
                                    page.title?.toLowerCase().includes(value) ||
                                    (page.subtitle &&
                                        page.subtitle.includes(value))
                            );

                            const newResulsSets = [
                                {
                                    id: 'account-group',
                                    label: 'Grupos de contas',
                                    results: grouped.get(
                                        SearchItemTypeEnum.GroupAccount
                                    ),
                                },
                                {
                                    id: 'account',
                                    label: 'Contas',
                                    results: grouped.get(
                                        SearchItemTypeEnum.Account
                                    ),
                                },
                                {
                                    id: 'user',
                                    label: 'Usuários',
                                    results: grouped.get(
                                        SearchItemTypeEnum.User
                                    ),
                                },
                                {
                                    id: 'pages',
                                    label: 'Sistema',
                                    results: pagesResults,
                                },
                            ];
                            this.resultSets = newResulsSets;
                            this.search.next(newResulsSets);
                        }
                    },
                });
            });

        this.breakpointObserver
            .observe([Breakpoints.Handset])
            .subscribe((result) => {
                this.isMobile = result.matches;
                if (this.isMobile) {
                    this.opened = false;
                } else {
                    this.opened = true;
                }
            });
    }

    /**
     * On destroy
     */
    ngOnDestroy(): void {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    private _routerLinkByType(result: SearchItem | FuseNavigationItem) {
        if (
            (result.type === SearchItemTypeEnum.Account ||
                result.type === SearchItemTypeEnum.GroupAccount ||
                result.type === SearchItemTypeEnum.User) &&
            result.account_id
        ) {
            return ['/contas', result.account_id];
        }
        if (result.type === 'basic') {
            return [result.link];
        }
        return ['/perfil'];
    }

    private clear() {
        this.searchControl.setValue('');
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * On keydown of the search input
     *
     * @param event
     */
    onKeydown(event: KeyboardEvent): void {
        // Escape
        if (event.code === 'Escape') {
            // If the appearance is 'bar' and the mat-autocomplete is not open, close the search
            if (this.appearance === 'bar' && !this._matAutocomplete.isOpen) {
                this.close();
            }
        }
    }

    /**
     * Open the search
     * Used in 'bar'
     */
    open(): void {
        // Return if it's already opened
        if (this.opened) {
            return;
        }

        // Open the search
        this.opened = true;
    }

    /**
     * Close the search
     * * Used in 'bar'
     */
    close(): void {
        if (this.isMobile) {
            this.opened = false;
        }
        this.searchControl.setValue('');
    }

    /**
     * Track by function for ngFor loops
     *
     * @param index
     * @param item
     */
    public trackByFn(index: number, item: any): any {
        return item.id || index;
    }

    navigateTo(result: SearchItem) {
        this.router.navigate(this._routerLinkByType(result));
        this.clear();
    }
}
