import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AppSnackbarComponent } from './app-snackbar.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { AppSnackbarService } from './app-snackbar.service';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

@NgModule({
    declarations: [AppSnackbarComponent],
    imports: [CommonModule, MatSnackBarModule, MatButtonModule, MatIconModule],
    providers: [AppSnackbarService],
})
export class AppSnackbarModule {}
