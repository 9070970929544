import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, ReplaySubject, tap } from 'rxjs';
import { Apollo, MutationResult } from 'apollo-angular';
import { ApolloQueryResult, gql } from '@apollo/client/core';
import {
    AccountResponse,
    CreateUserInput,
    Feature,
    SearchUsersInput,
    SortOrderEnum,
    UpdateAccountInput,
    UpdateUserInput,
    User,
    UserEdgesResponse,
    UserResponse,
    UserSecret,
} from '../../../../graphql/generated';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private _user: ReplaySubject<User> = new ReplaySubject<User>(1);

    /**
     * Constructor
     */
    constructor(private _httpClient: HttpClient, private _apollo: Apollo) {}

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for user
     *
     * @param value
     */
    set user(value: User) {
        // Store the value
        this._user.next(value);
    }

    get user$(): Observable<User> {
        return this._user.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Public methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Get the current logged in user data : Observable<User>
     */
    public getMyUser() {
        const MY_USER = gql`
            query MyUser {
                myUser {
                    id
                    login
                    role
                    permissions
                    account {
                        id
                        account
                        active
                        group {
                            account
                        }
                    }
                    deleted_at {
                        transform(format: "DD-MM-YYYY")
                    }
                }
            }
        `;
        this._apollo
            .watchQuery({
                query: MY_USER,
            })
            .valueChanges.subscribe((response: any) => {
                this._user.next(response.data.myUser);
            });
    }

    /**
     * Get the current logged in user data : Observable<User>
     */
    public getMyUserComplete(): Observable<User> {
        const MY_USER = gql`
            query MyUser {
                myUser {
                    id
                    login
                    role
                    permissions
                    created {
                        transform(format: "DD-MM-YYYY")
                    }
                    deleted_at {
                        transform(format: "DD-MM-YYYY")
                    }
                    last_login {
                        transform(format: "DD-MM-YYYY hh:mm")
                    }
                    modified {
                        transform(format: "DD-MM-YYYY hh:mm")
                    }
                    deleted_reason
                    account {
                        id
                        account
                        active
                        group {
                            account
                        }
                    }
                }
            }
        `;
        return this._apollo
            .watchQuery({
                query: MY_USER,
            })
            .valueChanges.pipe(
                map((result: ApolloQueryResult<{ myUser: User }>) => {
                    return result.data.myUser;
                })
            );
    }

    /**
     * Get the current logged in user data
     */
    get(): Observable<User> {
        return this._httpClient.get<User>('api/common/user').pipe(
            tap((user) => {
                this._user.next(user);
            })
        );
    }

    /**
     * Update the user
     *
     * @param user
     */
    update(user: User): Observable<any> {
        return this._httpClient.patch<User>('api/common/user', { user }).pipe(
            map((response) => {
                this._user.next(response);
            })
        );
    }

    public getUsersByAccount(accountId): Observable<User[]> {
        const USERS = gql`
            query Users($params: SearchUsersInput) {
                users(params: $params) {
                    edges {
                        id
                        login
                        role
                        created {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        modified {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        deleted_at {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        last_login {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        last_login_attempt {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        last_login_error
                    }
                }
            }
        `;
        const params: SearchUsersInput = {
            per_page: -1,
            sort: {
                created: SortOrderEnum.Asc,
            },
            and: {
                account: accountId,
            },
        };

        return this._apollo
            .watchQuery({
                query: USERS,
                fetchPolicy: 'no-cache',
                variables: { params },
            })
            .valueChanges.pipe(
                map(
                    (
                        response: ApolloQueryResult<{
                            users: UserEdgesResponse;
                        }>
                    ) => response.data.users.edges
                )
            );
    }

    public createUser(
        input: CreateUserInput
    ): Observable<MutationResult<{ createUser: UserResponse }>> {
        const CREATE_USER = gql`
            mutation CreateUser($input: CreateUserInput!) {
                createUser(input: $input) {
                    message
                    data {
                        id
                        login
                        last_login {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        modified {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        created {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                    }
                }
            }
        `;

        const treatedInput: CreateUserInput = {
            ...input,
            login: input.login.trim(),
            password: input.password.trim(),
        };

        return this._apollo.mutate<{ createUser: UserResponse }>({
            mutation: CREATE_USER,
            fetchPolicy: 'no-cache',
            variables: {
                input: treatedInput,
            },
        });
    }

    public updateUser(
        id: number,
        input: UpdateUserInput
    ): Observable<MutationResult<{ updateUser: UserResponse }>> {
        const UPDATE_USER = gql`
            mutation UpdateUser($id: Int!, $input: UpdateUserInput!) {
                updateUser(id: $id, input: $input) {
                    message
                    data {
                        id
                        login
                        last_login {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        modified {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        created {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                    }
                }
            }
        `;

        const treatedInput: UpdateUserInput = {
            ...input,
            login: input.login.trim(),
            password: input.password.trim(),
        };

        return this._apollo.mutate<{ updateUser: UserResponse }>({
            mutation: UPDATE_USER,
            fetchPolicy: 'no-cache',
            variables: {
                id,
                input: treatedInput,
            },
        });
    }

    public remove(
        id: number
    ): Observable<MutationResult<{ removeUser: UserResponse }>> {
        const REMOVE_USER = gql`
            mutation RemoveUser($id: Int!) {
                removeUser(id: $id) {
                    message
                    data {
                        id
                        login
                        last_login {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        modified {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                        created {
                            transform(format: "DD-MM-YYYY hh:mm")
                        }
                    }
                }
            }
        `;
        return this._apollo.mutate<{ removeUser: UserResponse }>({
            mutation: REMOVE_USER,
            variables: {
                id,
            },
        });
    }

    getSecretsByUser(userId): Observable<UserSecret[]> {
        const USERS = gql`
            query SecretsByUser($id: Int!) {
                secretsByUser(id: $id) {
                    name
                    login
                    password
                }
            }
        `;

        return this._apollo
            .watchQuery({
                query: USERS,
                fetchPolicy: 'no-cache',
                variables: { id: userId },
            })
            .valueChanges.pipe(
                map(
                    (
                        response: ApolloQueryResult<{
                            secretsByUser: UserSecret[];
                        }>
                    ) => response.data.secretsByUser
                )
            );
    }
}
